import { useEffect, useState } from 'react';
import {
  Bell,
  Clipboard,
  Clock,
  List,
  PenTool,
  Sliders,
  RefreshCcw,
} from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

const restApi = axios.create({
  baseURL: `${import.meta.env.VITE_HELPER_API}/api/v1`,
});

const ROUTES = [
  {
    icon: <List />,
    label: 'Property Management',
    path: '/property-management',
  },
  { icon: <PenTool />, label: 'Allocation', path: '/allocation' },
  { icon: <Sliders />, label: 'Model Setup', path: '/model-setup' },
  {
    icon: <Clipboard />,
    label: 'Reporting',
    path: '/reporting',
  },
  { icon: <Clock />, label: 'Change Log', path: '/change-log' },
];
interface SyncStatusResponse {
  changelog: string[];
  isRunning: boolean;
  lastStatus: 'Running' | 'Idle';
}

function Header() {
  const { pathname } = useLocation();
  const [syncStatus, setSyncStatus] = useState<SyncStatusResponse>();
  const [syncLoading, setSyncLoading] = useState(false);

  const getStatus = async () => {
    setSyncLoading(true);
    try {
      const response = await restApi.get<SyncStatusResponse>('/sync/status');
      setSyncStatus(response.data);
    } catch (error) {
      console.error('Error triggering sync:', error);
    } finally {
      setSyncLoading(false);
    }
  };

  const triggerSync = async () => {
    setSyncLoading(true);
    try {
      await restApi.post('/sync');
      getStatus();
    } catch (error) {
      console.error('Error triggering sync:', error);
    } finally {
      setSyncLoading(false);
    }
  };

  useEffect(() => {
    getStatus();
  }, []);

  const statusText = syncStatus
    ? `Status: ${syncStatus.lastStatus} - ${syncStatus.isRunning ? 'Running' : 'Idle'}`
    : 'Sync not triggered';

  return (
    <AppBar
      position="static"
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 100,
        backgroundColor: 'blackWhite.white',
        boxShadow: 'none',
        borderBottom: '1px solid',
        borderBottomColor: 'blackWhite.200',
        px: 5,
        borderRadius: 0,
      }}
    >
      <Toolbar disableGutters sx={{ width: '100%' }}>
        <Box
          component="a"
          href="/"
          sx={{
            position: 'relative',
            aspectRatio: 1,
            height: 56,
            width: 200,
            display: 'flex',
          }}
        >
          <Box
            component="img"
            src={'/images/Logo.png'}
            alt="Logo"
            sx={{
              objectFit: 'contain',
              width: '188px',
            }}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'flex' },
            minHeight: 'inherit',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {ROUTES.map(({ icon, label, path }) => {
            const isActive = path === pathname;
            return (
              <MenuItem
                key={label}
                sx={{
                  p: 0,
                  cursor: 'pointer',
                }}
              >
                <Box
                  component={Link}
                  to={path}
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1.4,
                    px: 3,
                    width: '100%',
                    minHeight: 70,
                    textAlign: 'center',
                    color: isActive ? 'primary.600' : 'blackWhite.400',
                    '&:after': {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      bottom: 0,
                      border: isActive ? '1px solid' : 'none',
                      borderColor: isActive ? 'primary.600' : 'none',
                      pointerEvents: 'none',
                    },
                  }}
                >
                  {icon}
                  {label}
                </Box>
              </MenuItem>
            );
          })}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <IconButton>
            <Bell style={{ color: 'blackWhite.400' }} />
          </IconButton>
          <Tooltip title={syncLoading ? 'Sync in progress...' : statusText}>
            <IconButton onClick={triggerSync} disabled={syncLoading}>
              {!syncStatus?.isRunning ? (
                <RefreshCcw style={{ color: 'blackWhite.400' }} />
              ) : (
                <CircularProgress size={24} />
              )}
            </IconButton>
          </Tooltip>
          <Avatar sx={{ width: 28, height: 28 }}>A</Avatar>
          <Typography sx={{ color: 'blackWhite.600' }}>
            Arthur Morghan
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
